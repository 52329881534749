<template>
    <header>
      <div id="header-sticky"
      :class="`tp-header-area tp-header-style-transparent-white tp-header-sticky tp-header-transparent has-dark-logo tp-header-height header-sticky ${isSticky ? 'header-sticky' : ''}`">
      <div class="tp-header-bottom-3 pl-85 pr-85">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-xl-2 col-lg-2 col-6">
              <div class="logo">
                <router-link to="/">
                  <img src="@/assets/img/logo/logo-web-light.png" alt="logo" height="55" class="logo-img logo-light" />
                  <img src="@/assets/img/logo/logo-web-light.png" alt="logo" height="55" class="logo-img logo-dark" />
                </router-link>
              </div>
            </div>
            <div class="col-xl-8 col-lg-8 d-none d-lg-block">
              <div class="main-menu menu-style-3 menu-style-4 p-relative">
                <nav class="tp-main-menu-content">
                  <!-- menus start -->
                  <HeaderComponentMenus />
                  <!-- menus end -->
                </nav>
              </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-6">
              <div class="tp-header-action d-flex align-items-center justify-content-end ml-50">
                <div class="tp-header-action-item d-none d-sm-block">
                  <router-link to="/wishlist" class="tp-header-action-btn">
                    <SvgWishlist />
                    <span class="tp-header-action-badge">{{wishlistStore.wishlists.length}}</span>
                  </router-link>
                </div>
                <div class="tp-header-action-item d-none d-sm-block">
                  <button @click="cartStore.handleCartOffcanvas" type="button" class="tp-header-action-btn cartmini-open-btn">
                    <SvgCartBag />
                    <span class="tp-header-action-badge">{{ cartStore.totalPriceQuantity.quantity }}</span>
                  </button>
                </div>
                <div class="tp-header-action-item d-lg-none">
                  <button @click="utilsStore.handleOpenMobileMenu()" type="button" class="tp-offcanvas-open-btn">
                    <SvgMenuIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>






      
      <!-- header sticky end -->
    </header>
    <!-- cart offcanvas start -->
    <OffcanvasCartSidebar/>
    <!-- cart offcanvas end -->
  
    <!-- cart offcanvas start -->
    <OffcanvasMobileSidebar product-type="electronics"/>
    <!-- cart offcanvas end -->
  </template>
  
<script setup>
import { useCartStore } from '@/stores/pinia/useCartStore'
import { useWishlistStore } from '@/stores/pinia/useWishlistStore'
import { useUtilityStore } from '@/stores/pinia/useUtilityStore'
import { useSticky } from '@/utils'
import HeaderSearch from '@/components/Header/HeaderSearch.vue'
import HeaderComponentMainRight from '@/components/Header/HeaderComponentMainRight.vue'
import HeaderComponentTopCategories from '@/components/Header/TopCategories.vue'
import OffcanvasCartSidebar from '@/components/Offcanvas/OffcanvasCartSidebar.vue'
import OffcanvasMobileSidebar from '@/components/Offcanvas/OffcanvasMobileSidebar.vue'
import HeaderComponentMenus from '@/components/Header/Menus.vue'
import SvgShippingCar from '@/components/Icons/svg/shipping-car.vue'
import SvgContact from '@/components/Icons/svg/contact.vue'
import SvgCompare from "@/components/Icons/svg/compare.vue";
import SvgWishlist from "@/components/Icons/svg/wishlist.vue";
import SvgCartBag from "@/components/Icons/svg/cart-bag.vue";
import SvgMenuIcon from "@/components/Icons/svg/menu-icon.vue";

const { isSticky } = useSticky()
const cartStore = useCartStore()
const wishlistStore = useWishlistStore()
const utilsStore = useUtilityStore()
</script>
<style>
.logo-img {
  filter: drop-shadow(#000 1px 1px 0px);
}
.main-menu.menu-style-3.menu-style-4 > nav > ul > li > a {
  color: var(--tp-common-black) !important;
}
</style>
  