import { type IMenuItem, type IMobileType } from "@/types/menu-d-type";

export const menu_data:IMenuItem[] = [
  {
    id:1,
    link:'/',
    title:'Home',
  },
  {
    id:2,
    link:'/pret',
    title:'Pret',
    mega_menu:false,
  },
  {
    id:3,
    link:'/sale',
    title:'Sale',
  },
  {
    id:4,
    link:'/contact',
    title:'Contact Us',
  },
]

// mobile menu data 
export const mobile_menu:IMobileType[] = [
  {
    id: 1,
    // homes: true,
    title: 'Home',
    link: '/',
    single_link: true,
  },
  {
    id:2,
    link:'/pret',
    title:'Pret',
    single_link: true,
  },
  {
    id:3,
    link:'/sale',
    title:'Sale',
    single_link: true,
  },
  {
    id:4,
    link:'/contact',
    title:'Contact Us',
    single_link: true,
  },
]