<template>
  <div
    :class="`offcanvas__area offcanvas__radius ${
      utilsStore.openMobileMenus ? 'offcanvas-opened' : ''
    }`"
  >
    <div class="offcanvas__wrapper">
      <div class="offcanvas__close">
        <button
          @click="utilsStore.handleOpenMobileMenu()"
          class="offcanvas__close-btn offcanvas-close-btn"
        >
          <SvgClose2 />
        </button>
      </div>
      <div class="offcanvas__content">
        <div
          class="offcanvas__top mb-70 d-flex justify-content-between align-items-center"
        >
          <div class="offcanvas__logo logo">
            <router-link to="/">
              <img src="@/assets/img/logo/logo-web-light.png" class="logo-img" alt="logo" height="35" />
            </router-link>
          </div>
        </div>
        <!-- mobile category start -->
        <!-- <HeaderComponentMobileCategories :product-type="productType" /> -->
        <!-- mobile category end -->
        <div class="tp-main-menu-mobile fix d-lg-none mb-40">
          <!-- mobile menus start -->
          <HeaderComponentMobileMenus />
          <!-- mobile menus end -->
        </div>

        
        <div class="offcanvas__btn">
          <router-link to="/contact" class="tp-btn-2 tp-btn-border-2"
            >Contact Us</router-link
          >
        </div>
      </div>
    </div>
  </div>

  <div
    @click="utilsStore.handleOpenMobileMenu()"
    :class="`body-overlay ${utilsStore.openMobileMenus ? 'opened' : ''}`"
  ></div>
</template>

<script setup lang="ts">
// import { ref } from 'vue';
import { useUtilityStore } from "@/stores/pinia/useUtilityStore";
import SvgClose2 from "@/components/Icons/svg/close-2.vue";
// import HeaderComponentMobileCategories from "@/components/Header/MobileCategories.vue";
import HeaderComponentMobileMenus from "@/components/Header/MobileMenus.vue";

const props = defineProps<{ productType: string }>();
const utilsStore = useUtilityStore();

// let isToggleActive = ref<string>("");
// handle active
// const handleToggleActive = (type: string) => {
//   if (type === isToggleActive.value) {
//     isToggleActive.value = "";
//   } else {
//     isToggleActive.value = type;
//   }
// };
</script>
