<template>
  <div
    v-if="utilityStore.modalId && utilityStore.product"
    class="modal fade tp-product-modal"
    :id="utilityStore.modalId"
    tabindex="-1"
    :aria-labelledby="utilityStore.modalId"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="tp-product-modal-content d-lg-flex align-items-start">
          <button
            type="button"
            class="tp-product-modal-close-btn"
            data-bs-toggle="modal"
            :data-bs-target="`#${utilityStore.modalId}`"
            ref="modalToggleButton"
          >
            <i class="fa-regular fa-xmark"></i>
          </button>
          <!-- product details thumb start -->
          <ProductDetailsThumb :product="utilityStore.product" />
          <!-- product details thumb end -->

          <!-- product details wrapper -->
          <ProductDetailsWrapper
            :product="utilityStore.product"
            :is-show-bottom="false"
            :modalRef="modalToggleButton"
          />
          <!-- product details wrapper -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useUtilityStore } from "@/stores/pinia/useUtilityStore";
import ProductDetailsThumb from "@/components/ProductDetails/ProductDetailsThumb.vue";
import ProductDetailsWrapper from "@/components/ProductDetails/ProductDetailsWrapper.vue";
const utilityStore = useUtilityStore();
const modalToggleButton = ref(null)
</script>
