import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { frappeRequest, setConfig } from 'frappe-ui'
import { GridItem, GridLayout } from 'grid-layout-plus'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import * as Sentry from "@sentry/vue";
import App from './App.vue'
import './index.css'
import 'vue3-toastify/dist/index.css'
import VueSocialSharing from '@/utils/social-share/vue-social-sharing'
// import './theme-bs.css'
import './theme.css'
import router from './router'
// import { initSocket } from './socket'
import { createToast } from './utils/toasts'

import { registerControllers, registerGlobalComponents } from './globals'

const app = createApp(App)

Sentry.init({
	app,
	dsn: "https://51523b2c6498c493b56e4e7fdfc9d672@o521207.ingest.us.sentry.io/4507646299930624",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [/^https:\/\/www\.asanaaye\.com/, /^https:\/\/asanaaye\.com/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const pinia = createPinia()
app.use(pinia)
setConfig('resourceFetcher', (options) => {
	return frappeRequest({
		...options,
		onError(err) {
			if (err.messages && err.messages[0]) {
				createToast({
					title: 'Error',
					variant: 'error',
					message: err.messages[0],
				})
			}
		},
	})
})

app.use(router)
app.use(autoAnimatePlugin)
app.use(VueSocialSharing)
app.component('grid-layout', GridLayout)
app.component('grid-item', GridItem)
// app.provide('$socket', initSocket())

registerGlobalComponents(app)
registerControllers(app)

app.mount('#app')
