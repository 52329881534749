import AvailableNetworks from './networks';
import {
    ref,
    computed,
    h,
    toRefs,
} from 'vue';

let $window = typeof window !== 'undefined' ? window : null;

export default {
    name: 'ShareNetwork',
    props: {
        network: {
            type: String,
            required: true
        },
        url: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            default: ''
        },
        quote: {
            type: String,
            default: ''
        },
        hashtags: {
            type: String,
            default: ''
        },
        twitterUser: {
            type: String,
            default: ''
        },
        media: {
            type: String,
            default: ''
        },
        tag: {
            type: String,
            default: 'a'
        },
        popup: {
            type: Object,
            default: () => ({
                width: 626,
                height: 436
            })
        }
    },
    emits: ['change', 'close', 'open'],
    setup(props, context) {
        const { slots, emit } = context;
        const popupTop = ref(0);
        const popupLeft = ref(0);
        const popupWindow = ref(undefined);
        const popupInterval = ref(null);

        const {
            network,
            url,
            title,
            description,
            quote,
            hashtags,
            twitterUser,
            media,
            tag,
            popup
        } = toRefs(props);

        const networks = computed(() => {
            return AvailableNetworks;
        });

        const key = computed(() => {
            return network.value.toLowerCase();
        });

        const rawLink = computed(() => {
            const ua = navigator.userAgent.toLowerCase();
            if (
                key.value === 'sms' &&
                (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1)
            ) {
                return networks.value[key.value].replace(':?', ':&');
            }
            return networks.value[key.value];
        });

        const shareLink = computed(() => {
            // console.log('check sharelink');
            let link = rawLink.value;

            if (key.value === 'twitter') {
                if (!hashtags.value.length) link = link.replace('&hashtags=@h', '');
                if (!twitterUser.value.length) link = link.replace('@tu', '');
            }

            return link
                .replace(/@tu/g, '&via=' + encodeURIComponent(twitterUser.value))
                .replace(/@u/g, encodeURIComponent(url.value))
                .replace(/@t/g, encodeURIComponent(title.value))
                .replace(/@d/g, encodeURIComponent(description.value))
                .replace(/@q/g, encodeURIComponent(quote.value))
                .replace(/@h/g, encodedHashtags.value)
                .replace(/@m/g, encodeURIComponent(media));
        });

        const encodedHashtags = computed(() => {
            // console.log('check encodedHashtags');
            if (key === 'facebook' && hashtags.value.length) {
                return '%23' + hashtags.value.split(',')[0];
            }

            return hashtags.value;
        });

        const resizePopup = () => {
            // console.log('resize popup method triggered here ');

            const width =
                $window.innerWidth ||
                document.documentElement.clientWidth ||
                $window.screenX;
            const height =
                $window.innerHeight ||
                document.documentElement.clientHeight ||
                $window.screenY;
            const systemZoom = width / $window.screen.availWidth;

            popupLeft.value =
                (width - popup.width) / 2 / systemZoom +
                ($window.screenLeft !== undefined
                    ? $window.screenLeft
                    : $window.screenX);
            popupTop.value =
                (height - popup.height) / 2 / systemZoom +
                ($window.screenTop !== undefined ? $window.screenTop : $window.screenY);
        };

        const share = () => {
            // debugger;
            resizePopup();

            // If a popup window already exist, we close it and trigger a change event.
            if (popupWindow.value) {
                clearInterval(popupInterval.value);

                // Force close (for Facebook)
                popupWindow.value.close();
                emit('change');
            }

            popupWindow.value = $window.open(
                shareLink.value,
                'sharer-' + key.value,
                ',height=' +
                    popup.value.height +
                    ',width=' +
                    popup.value.width +
                    ',left=' +
                    popupLeft.value +
                    ',top=' +
                    popupTop.value +
                    ',screenX=' +
                    popupLeft.value +
                    ',screenY=' +
                    popupTop.value
            );

            // If popup are prevented (AdBlocker, Mobile App context..), popup.window stays undefined and we can't display it
            if (!popupWindow.value) return;

            popupWindow.value.focus();

            // Create an interval to detect popup closing event
            popupInterval.value = setInterval(() => {
                if (!popupWindow.value || popupWindow.value.closed) {
                    clearInterval(popupInterval.value);

                    popupWindow.value = null;

                    emit('close');
                }
            }, 500);

            emit('open');
        };

        const touch = () => {
            // console.log('touch method triggered');
            window.open(shareLink.value, '_blank');
            emit('open');
        };

        const renderData = () => {
            if (!networks.value.hasOwnProperty(key.value)) {
                throw new Error('Network ' + key.value + ' does not exist');
            }

            const node = {
                class: 'share-network-' + key.value,
                on: {
                    click: rawLink.value.substring(0, 4) === 'http' ? share : touch
                },
                attrs: {
                    href:
                        rawLink.value.substring(0, 4) === 'http'
                            ? shareLink.value
                            : rawLink.value
                }
            };

            if (tag.value === 'a') {
                node.attrs = { href: 'javascript:void(0)' };
            }
            const content = slots.default ? slots.default() : null;
            return [tag.value, node, content];
        };

        const data = renderData();
        const tg = `${data[0]}`;
        const node = data[1];
        const content = data[2];

        return () =>
            h(
                tg,
                {
                    onClick: node.on.click,
                    href: node.attrs.href
                },
                content
            );
    }
};