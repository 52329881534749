<template>
  <div class="tp-product-details-wrapper has-sticky">
    <div class="tp-product-details-category">
      <span>{{ product.item_group }}</span>
    </div>
    <h3 itemprop="name" class="tp-product-details-title">{{ product.web_item_name }}</h3>

    <!-- inventory details -->
    <div class="tp-product-details-inventory d-flex align-items-center mb-10">
      <div class="tp-product-details-stock mb-10">
        <span itemprop="itemCondition" itemscope itemtype="http://schema.org/OfferItemCondition">New</span> &nbsp;
        <span itemprop="availability" itemscope itemtype="http://schema.org/ItemAvailability"> {{ product.status ?? 'In Stock' }}</span>
      </div>
      <!-- <div class="tp-product-details-rating-wrapper d-flex align-items-center mb-10">
        <div class="tp-product-details-rating">
          <span><i class="fa-solid fa-star"></i></span>
          <span><i class="fa-solid fa-star"></i></span>
          <span><i class="fa-solid fa-star"></i></span>
          <span><i class="fa-solid fa-star"></i></span>
          <span><i class="fa-solid fa-star"></i></span>
        </div>
        <div class="tp-product-details-reviews">
          <span>({{ product.reviews?.length }} Reviews)</span>
        </div>
      </div> -->
    </div>
    <!-- <p><p v-html="getDescription"></p> <span @click="textMore = !textMore">{{textMore ? 'See less' : 'See more'}}</span></p> -->
    <p v-html="product.description" itemprop="description"></p>

    <!-- price -->
    <div class="tp-product-details-price-wrapper mb-20" itemprop="offers" itemscope itemtype="https://schema.org/Offer">
      <span itemprop="priceCurrency" class="d-none" content="PKR">Rs</span>
      <span itemprop="price" :content="product.price_list_rate" class="d-none">{{ product.price_list_rate }}</span>
      <div v-if="productStore.get_pricing?.formatted_discount_rate">
        <span class="tp-product-details-price old-price">{{ productStore.get_pricing.formatted_mrp }}</span>
        <span class="tp-product-details-price new-price">
          {{ productStore.get_pricing.formatted_price }}
        </span>
      </div>
      <span v-else class="tp-product-details-price">{{ productStore.get_pricing?.formatted_price }}</span>
    </div>

    <!-- variations -->
    <div v-if="hasVariants" class="tp-product-details-variation">
      <div class="tp-product-details-variation-item" v-for="vAttr in product.attributes">
        <h4 class="tp-product-details-variation-title">{{ vAttr.attribute.name }} :</h4>
        <div class="tp-product-details-variation-lis">
          <button v-for="(vItem, i) in vAttr.attribute.values" :key="i"
            @click="productStore.handleActiveVariant(vAttr.attribute.name, vItem)" type="button"
            :class="[vItem === productStore.checkActiveVariant(vAttr.attribute.name) ? 'active' : '']"
            class="tp-product-details-variant-value-btn mb-2" style="margin-right:5px">
            {{ vItem }}
          </button>
          <!-- <button v-for="(vItem, i) in vAttr.values" :key="i" @click="productStore.handleImageActive(item.img)"
            type="button"
            :class="['color', 'tp-color-variation-btn', vItem.img === productStore.activeImg ? 'active' : '']"
            style="margin-right:5px">
            <span :data-bg-color="vItem.color?.clrCode" :style="`background-color:${vItem.color?.clrCode}`"></span>
            <span class="tp-color-variation-tootltip">
              {{ vItem }}
            </span>
          </button> -->
        </div>
      </div>
    </div>
    <!-- <div v-if="hasColorData" class="tp-product-details-variation">
      <div class="tp-product-details-variation-item">
        <h4 class="tp-product-details-variation-title">Color :</h4>
        <div class="tp-product-details-variation-list">
          <button v-for="(item, i) in product.imageURLs" :key="i" @click="productStore.handleImageActive(item.img)"
            type="button"
            :class="['color', 'tp-color-variation-btn', item.img === productStore.activeImg ? 'active' : '']"
            style="margin-right:5px">
            <span :data-bg-color="item.color?.clrCode" :style="`background-color:${item.color?.clrCode}`"></span>
            <span v-if="item.color && item.color.name" class="tp-color-variation-tootltip">
              {{ item.color.name }}
            </span>
          </button>
        </div>
      </div>
    </div> -->

    <!-- product countdown start -->
    <div v-if="product.offerDate?.endDate">
      <ProductDetailsCountdown :product="product" />
    </div>
    <!-- product countdown end -->

    <!-- actions -->
    <div class="tp-product-details-action-wrapper">
      <h3 class="tp-product-details-action-title">Quantity</h3>
      <div class="tp-product-details-action-item-wrapper d-flex align-items-center">
        <div class="tp-product-details-quantity">
          <div class="tp-product-quantity mb-15 mr-15">
            <span class="tp-cart-minus" @click="cartStore.decrement">
              <SvgMinus />
            </span>
            <input class="tp-cart-input" type="text" :value="cartStore.orderQuantity" disabled>
            <span class="tp-cart-plus" @click="cartStore.increment">
              <SvgPlusSm />
            </span>
          </div>
        </div>
        <div class="tp-product-details-add-to-cart mb-15 w-100">
          <button @click="cartStore.addCartProduct(product, false, !isShowBottom, props.modalRef)"
            class="tp-product-details-add-to-cart-btn w-100">Add To
            Cart</button>
        </div>
      </div>
      <button @click="buyNow" class="tp-product-details-buy-now-btn w-100 text-center">Buy Now</button>
    </div>
    <div class="tp-product-details-action-sm">
      <button @click="compareStore.add_compare_product(product)" type="button" class="tp-product-details-action-sm-btn">
        <SvgCompare3 />
        Compare
      </button>
      <button @click="wishlistStore.add_wishlist_product(product)" type="button"
        class="tp-product-details-action-sm-btn">
        <SvgWishlist3 />
        {{ isItemInWishlist(product) ? 'Remove From Wishlist' : 'Add To Wishlist' }}
      </button>
      <!-- <button type="button" class="tp-product-details-action-sm-btn">
          <SvgAskQuestion/>
          Ask a question
      </button> -->
    </div>

    <div v-if="isShowBottom">
      <div class="tp-product-details-query">
        <div class="tp-product-details-query-item d-flex align-items-center">
          <span>SKU: </span>
          <p>{{ product.item_code }}</p>
        </div>
        <div class="tp-product-details-query-item d-flex align-items-center">
          <span>Category: </span>
          <p>{{ product.item_group }}</p>
        </div>
        <div class="tp-product-details-query-item d-flex align-items-center" v-if="product.brand">
          <span>Brand: </span>
          <p itemprop="brand">{{ product.brand }}</p>
        </div>
      </div>
      <div class="tp-product-details-social">
        <span>Share: </span>
        <!-- Add more social sharing buttons as needed -->
        <!-- <ShareNetwork
        network="facebook"
        :url="currentUrl"
        :title="product.web_item_name"
        :description="product.description"
        :quote="yourQuote"
        :hashtags="yourHashtags"
        :twitterUser="yourTwitterUser"
        :media="yourMedia"
      /> -->
        <ShareNetwork network="facebook" :url="currentUrl" :title="product.web_item_name"
          :description="removeHtmlTags(product.description)" :media="product.website_image" :hashtags="`#asanaaye`"><i
            class="fa-brands fa-facebook-f"></i>
        </ShareNetwork>
        <ShareNetwork network="twitter" :url="currentUrl" :title="product.web_item_name"
          :description="removeHtmlTags(product.description)" :media="product.website_image">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="height: 14px;">
            <path
              d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
          </svg>
        </ShareNetwork>
        <ShareNetwork network="whatsapp" :url="currentUrl" :title="product.web_item_name"
          :description="removeHtmlTags(product.description)" :media="product.website_image"><i
            class="fa-brands fa-whatsapp"></i>
        </ShareNetwork>
        <ShareNetwork network="messenger" :url="currentUrl" :title="product.web_item_name"
          :description="removeHtmlTags(product.description)" :media="product.website_image"><i
            class="fa-brands fa-facebook-messenger"></i>
        </ShareNetwork>
        <ShareNetwork network="email" :url="currentUrl" :title="product.web_item_name"
          :description="removeHtmlTags(product.description)" :media="product.website_image"><i
            class="fa fa-envelope"></i>
        </ShareNetwork>
      </div>
      <!-- <div class="tp-product-details-msg mb-15">
      <ul>
          <li>30 days easy returns</li>
          <li>Order yours before 2.30pm for same day dispatch</li>
      </ul>
    </div>
    <div class="tp-product-details-payment d-flex align-items-center flex-wrap justify-content-between">
      <p>Guaranteed safe <br> & secure checkout</p>
      <img src="@/assets/img/product/icons/payment-option.png" alt="">
    </div> -->
    </div>

  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useProductStore } from '@/stores/pinia/useProductStore';
import { type IProduct } from '@/types/product-type';
import { useCartStore } from "@/stores/pinia/useCartStore";
import { useCompareStore } from "@/stores/pinia/useCompareStore";
import { useWishlistStore } from "@/stores/pinia/useWishlistStore";
import ProductDetailsCountdown from './ProductDetailsCountdown.vue';
import SvgMinus from '@/components/Icons/svg/minus.vue';
import SvgPlusSm from '@/components/Icons/svg/plus-sm.vue';
import SvgCompare3 from '@/components/Icons/svg/compare-3.vue';
import SvgWishlist3 from '@/components/Icons/svg/wishlist-3.vue';
// import SvgAskQuestion from '@/components/Icons/svg/ask-question.vue';

// store
const compareStore = useCompareStore();
const wishlistStore = useWishlistStore();
const productStore = useProductStore();
const cartStore = useCartStore();
// const router = useRouter();
const route = useRoute();
// props
const props = withDefaults(defineProps<{ product: IProduct; isShowBottom?: boolean; modalRef?: any }>(), {
  isShowBottom: true,
  modalRef: false
})
// const textMore = ref<boolean>(false)
productStore.setActiveProduct(props.product);

if ((props.product.attributes?.length || 0) > 0) {
  let vattrs = {}
  props.product.attributes.forEach((v) => {
    // console.log(v.attribute)
    vattrs[v.attribute.name] = ''
    productStore.handleActiveVariant(v.attribute.name, '')
  })
}

// const hasColorData = computed(() =>
//   // props.product.imageURLs.some(item => item?.color && item?.color?.name)
//   false
// );

const hasVariants = computed(() =>
  props.product.attributes?.length ?? 0 > 0
);

function isItemInWishlist(product: IProduct) {
  return wishlistStore.wishlists.some((prd) => prd.name === product.name);
}

// const getDescription = computed(() => {
//   return textMore.value ? props.product.description : props.product.description.substring(0, 99)
// })

function removeHtmlTags(description: string) {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = description;
  return tempElement.textContent || tempElement.innerText || '';
}

function buyNow() {
  cartStore.addCartProduct(props.product, true, !props.isShowBottom, props.modalRef)
}

// const selectedVariant = computed(() => {
//   console.log(productStore.activeVariant)
//   return productStore.activeVariant
// })
// const currentUrl = computed(() => window.location.href)
const currentUrl = computed(() => `https://www.asanaaye.com${route.fullPath}`)
</script>
