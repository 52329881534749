<template>
  <div class="tp-product-details-thumb-wrapper tp-tab d-sm-flex" ref="mainWrapper">
    <nav>
      <div class="nav nav-tabs flex-sm-column" id="productDetailsNavThumb" role="tablist" :style="`height: ${swiperHeight}px`">
        <Swiper v-bind="slider_setting" class="tp-product-related-slider-active swiper-container mb-10">
          <SwiperSlide v-for="(item, i) in productImages" :key="i">
            <!-- <button @click="productStore.handleVariantImageSelection(item)" -->
            <button @click="productStore.handleImageActive(item.website_image)"
              :class="`nav-link ${item.website_image === productStore.activeImg ? 'active' : ''}`">
              <img :src="item.website_image" :alt="product.web_item_name" />
            </button>
          </SwiperSlide>
        </Swiper>
      </div>
    </nav>
    <div class="tab-content m-img w-100" id="productDetailsNavContent">
      <div>
        <div class="tp-product-details-nav-main-thumb" style="background-color: #f5f6f8">
          <InnerImageZoom itemprop="image" :src="productStore.activeImg" :zoomSrc="getZoomSrc(productStore.activeImg)" :alt="product.web_item_name" class="w-100" zoomType="hover" :zoomPreload="false" :hideHint="true" />
          <div v-if="product.videoId" class="tp-product-details-thumb-video">
            <a @click="utilsStore.playVideo(product.videoId)"
              class="tp-product-details-thumb-video-btn cursor-pointer popup-video">
              <i class="fas fa-play"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- modal video component -->
  <ModalVideo v-if="product.videoId" />
  <!-- modal video component -->
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { Swiper, SwiperSlide } from "swiper/vue"
import { FreeMode, Scrollbar, Mousewheel } from "swiper/modules"
import { type IProduct } from "@/types/product-type"
import { useScreenSize } from '@/utils'
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'
import InnerImageZoom from 'vue-inner-image-zoom'
import { useProductStore } from "@/stores/pinia/useProductStore"
import { useUtilityStore } from "@/stores/pinia/useUtilityStore"
import ModalVideo from "@/components/Modal/ModalVideo.vue"
// props
const props = defineProps<{ product: IProduct }>();

const mainWrapper = ref(null)
const screenSize = useScreenSize()
const swiperHeight = computed(() => {
  if(screenSize.width < 575)
  {
    return 120;
  }
  else if (mainWrapper.value && mainWrapper.value) {
    const width = mainWrapper.value.getBoundingClientRect().width;
    return width - 78;
  }
  else {
    return 400;
  }
})
// const mainImageHeight = () => {
//   mainImageD.getBoundingClientRect().height
// }

const productStore = useProductStore();
const utilsStore = useUtilityStore();

const productImages = computed(() => {
  let items:any = []
  props.product.variants?.forEach((p) => {
    if(p.website_image)
    {
      items.push(p)
    }
  })
  items.push({
    website_image: props.product.website_image
  })
  props.product.extra_images?.forEach((i) => {
    items.push({
      website_image: i
    })
  })
  return items
})

// slider_setting
const slider_setting = computed(() => {
  return {
    // slidesPerView: 6,
    spaceBetween: 4,
    enteredSlides: false,
    direction: screenSize.width > 575 ? "vertical" : "horizontal",
    slidesPerView: 'auto',
    freeMode: true,
    scrollbar: true,
    mousewheel: true,
    modules: [FreeMode, Scrollbar, Mousewheel]
    // scrollbar: {
    //   el: ".tp-related-swiper-scrollbar",
    //   draggable: true,
    //   dragClass: "tp-swiper-scrollbar-drag",
    //   snapOnRelease: true,
    // },
    // breakpoints: {
    //   "1200": {
    //     slidesPerView: 4,
    //   },
    //   "992": {
    //     slidesPerView: 3,
    //   },
    //   "768": {
    //     slidesPerView: 2,
    //   },
    //   "576": {
    //     slidesPerView: 2,
    //   },
    //   "0": {
    //     slidesPerView: 1,
    //   },
    // },
  }
});

function getZoomSrc(img:string) {
  if(img=='') return
  let s = img.split('.')
  let p = ''
  for(let i=0; i<s.length; i++)
  {
    if(i==s.length-1)
    {
      p = p + '-org'
    }
    p = (p =='') ? s[i] : p + '.' + s[i]
  }
  return p
}
</script>
<style scoped>
.tp-product-details-thumb-wrapper .swiper-vertical .swiper-slide {
  height: 100px;
}
.tp-product-details-thumb-wrapper .swiper-horizontal .swiper-slide {
  width: 100px;
}
</style>
