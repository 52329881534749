// import product_data from '@/data/product-data';
import DefaultImage from '@/assets/img/product/2/prodcut-2.jpg';
import { IProduct } from '@/types/product-type';
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useProductStore = defineStore("product", () => {

  let activeImg = ref<string>(DefaultImage);
  let activeVariant = ref<Object>({});
  let activeProduct = ref<IProduct>();
  let variantItem = ref<IProduct>();
  let openFilterDropdown = ref<boolean>(false);
  let openFilterOffcanvas = ref<boolean>(false);

  // handle image active
  const handleImageActive = (img: string) => {
    // console.log(activeProduct.value)
    activeImg.value = img;
  };

  const get_pricing = computed(() => {
    try
    {
      if(activeProduct.value?.has_variants==1)
      {
        // console.log('activeVariant Pricing')
        // console.log('activeVariant', variantItem.value)
        return variantItem.value || {'formatted_price': 'Select Variation'}
      }
      return activeProduct.value || false
    }
    catch(e)
    {
      // console.log('caught error in Pricing')
      return activeProduct.value
    }
  })

  const handleActiveVariant = (attribute: string, v: string) => {
    activeVariant.value[attribute] = v;
    let allSelected = true;
    try {
      activeProduct.value?.attributes.forEach((a) => {
        if (activeVariant.value[a.attribute?.name] == '') {
          allSelected = false;
        }
      })
    }
    catch (e) {
      allSelected = false
    }
    if (allSelected) {
      const match = activeProduct.value?.variants.find(item => {
        const itemAttributeNames = item.attribute_values.map(attr => attr.name).sort().toString();
        const selectedAttributeNames = Object.keys(activeVariant.value).sort().toString();
        if (itemAttributeNames === selectedAttributeNames) {
          for (const key in activeVariant.value) {
            const attributeValue = item.attribute_values.find(attr => attr.name === key);
            if (!attributeValue || attributeValue.value !== activeVariant.value[key]) {
              return false;
            }
          }
          return true;
        }
        return false;
      });
      if(match)
      {
        // console.log(match)
        // variantItem.value = match as IProduct;
        variantItem.value = {...activeProduct.value, ...match} as IProduct;
        variantItem.value.attributes = []
        variantItem.value.has_variants = 0
        variantItem.value.variants = []
        variantItem.value.price_list_rate = match.price.price_list_rate
        variantItem.value.discount = match.price.discount
        variantItem.value.formatted_price = match.price.formatted_price
        variantItem.value.formatted_discount_rate = match.price.formatted_discount_rate
        variantItem.value.formatted_mrp = match.price.formatted_mrp
        if(match.website_image)
        {
          handleImageActive(match.website_image)
        }
      }
    }
    // console.log(allSelected)
    // console.log(activeProduct.value)
    // console.log(activeVariant.value)
  }

  function checkActiveVariant(attribute: string) {
    try {
      return activeVariant.value[attribute];
    }
    catch (e) {
      return '';
    }
  }

  const handleVariantImageSelection = (product: IProduct["variants"][0]) => {
    product.attribute_values.forEach((v) => {
      handleActiveVariant(v.name, v.value)
    })
  }

  const setActiveProduct = (product: IProduct) => {
    // console.log('setActiveItem', product)
    activeProduct.value = product
    activeVariant.value = {}
  }

  // handle image active
  const handleOpenFilterDropdown = () => {
    openFilterDropdown.value = !openFilterDropdown.value
  };

  // handle image active
  const handleOpenFilterOffcanvas = () => {
    openFilterOffcanvas.value = !openFilterOffcanvas.value
  };

  return {
    activeImg,
    activeProduct,
    activeVariant,
    variantItem,
    get_pricing,
    setActiveProduct,
    handleVariantImageSelection,
    handleImageActive,
    handleActiveVariant,
    checkActiveVariant,
    handleOpenFilterDropdown,
    openFilterDropdown,
    openFilterOffcanvas,
    handleOpenFilterOffcanvas,
  };
});
