<template>
	<div class="flex h-screen w-screen bg-white antialiased">
		<BaseLayout>
			<Suspense>
				<RouterView v-slot="{ Component }">
					<Suspense>
						<div class="flex flex-1 flex-col">
							<component :is="Component" :key="$route.fullPath" />
						</div>
						<template #fallback>
							<SuspenseFallback />
						</template>
					</Suspense>
				</RouterView>
			</Suspense>
		</BaseLayout>
		<Dialogs />
		<Toaster :visible-toasts="2" :rich-colors="true" position="bottom-right" />
	</div>
</template>
<script setup>
import AppShell from '@/components/AppShell.vue'
import SuspenseFallback from '@/components/SuspenseFallback'
import BaseLayout from '@/layouts/BaseLayout.vue'
import sessionStore from '@/stores/sessionStore'
import { computed, inject, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import { Toaster } from 'vue-sonner'
import { Dialogs } from '@/utils/dialogs'

const route = useRoute()
const isGuestView = computed(() => route.meta.isGuestView)



if (!isGuestView.value) {
	// const $socket = inject('$socket')
	const $notify = inject('$notify')
	const session = sessionStore()
	const l = session.isLoggedIn
	// $socket.on('sofomart_notification', (data) => {
	// 	if (data.user == session.user.user_id) {
	// 		$notify({
	// 			title: data.title || data.message,
	// 			message: data.title ? data.message : '',
	// 			variant: data.type,
	// 		})
	// 	}
	// })
	// onBeforeUnmount(() => {
	// 	$socket.off('sofomart_notification')
	// })
}
</script>
<style lang="scss">
@import "bootstrap/scss/bootstrap.scss";
@import "swiper/css/bundle";
@import "@/assets/css/font-awesome-pro.css";
@import "@/assets/css/flaticon_shofy.css";
@import "@/assets/scss/main.scss";
</style>