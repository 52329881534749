const debug = false;

export async function addToCart(title) {
    try {
        gtag('event', 'add_to_cart', {
            'event_category' : 'ecommerce',
            'event_label' : title
          });
        gtag('event', 'add_to_cart', {
            'event_category' : 'engagement',
            'event_label' : title
          });
        if(debug)
        {
            console.log('add_to_cart',title)
        }
    } catch (error) {
        // ignore
    }
}

export async function removeFromCart(title) {
    try {
        gtag('event', 'remove_from_cart', {
            'event_category' : 'ecommerce',
            'event_label' : title
          });
        gtag('event', 'remove_from_cart', {
            'event_category' : 'engagement',
            'event_label' : title
          });
        if(debug)
        {
            console.log('remove_from_cart',title)
        }
    } catch (error) {
        // ignore   
    }
}

export async function viewItem(title) {
   try {
     gtag('event', 'view_item', {
         'event_category' : 'ecommerce',
         'event_label' : title
       });
     gtag('event', 'view_item', {
         'event_category' : 'engagement',
         'event_label' : title
       });
     if(debug)
     {
         console.log('viewItem',title)
     }
   } catch (error) {
        // ignore
   }
}

export async function viewItemList(title) {
    try {
        gtag('event', 'view_item_list', {
            'event_category' : 'ecommerce',
            'event_label' : title
          });
        gtag('event', 'view_item_list', {
            'event_category' : 'engagement',
            'event_label' : title
          });
        if(debug)
        {
            console.log('view_item_list',title)
        }
    } catch (error) {
        // ignore
    }
}

export async function beginCheckout() {
    try {
        gtag('event', 'begin_checkout', {
            'event_category' : 'ecommerce',
          });
        gtag('event', 'begin_checkout', {
            'event_category' : 'engagement',
          });
        if(debug)
        {
            console.log('begin_checkout')
        }
    } catch (error) {
        // ignore
    }
}

export async function purchase(purchaseId, value, items) {
    try {
        gtag('event', 'purchase', {
            'event_category' : 'ecommerce',
            'transaction_id': purchaseId,
            'value': value,
            'items': items
          });
        gtag('event', 'purchase', {
            'event_category' : 'engagement',
            'transaction_id': purchaseId,
            'value': value,
            'items': items
          });
        if(debug)
        {
            console.log('purchase', {
            'transaction_id': purchaseId,
            'value': value,
            'items': items
            })
        }
    } catch (error) {
        // ignore
    }
}
