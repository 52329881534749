import { ref, onMounted, computed, watch } from "vue";
import { IProduct } from "@/types/product-type";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import { useRoute, useRouter } from 'vue-router'
import { useProductStore } from '@/stores/pinia/useProductStore';
import { addToCart, removeFromCart } from '@/utils/conversions/gtag'
import { addToCart as addToCartMeta } from '@/utils/conversions/meta'

export const useCartStore = defineStore("cart_product", () => {
  const route = useRoute();
  const router = useRouter();
  let cart_products = ref<IProduct[]>([]);
  let orderQuantity = ref<number>(1);
  let cartOffcanvas = ref<boolean>(false);
  const productStore = useProductStore();

  // add_cart_product
  const addCartProduct = (payload: IProduct, buyNow: Boolean = false, modal: Boolean = false, modalRef: any = false) => {
    let isExist = cart_products.value.some((i) => i.name === payload.name);
    if(payload.has_variants)
    {
      if(route.fullPath!='/'+payload.route && modal==false)
      {
        router.push(`/${payload.route}`)
        return;
      }
      // console.log(productStore.activeVariant);
      let variantsSelected = true;
      if(payload.has_variants)
      {
        payload.attributes.forEach((vAttr) => {
          if(!productStore.checkActiveVariant(vAttr.attribute?.name))
          {
            toast.error(`Please Select ${vAttr.attribute?.name}`)
            variantsSelected = false;
          }
        })
      }
      if(variantsSelected==false)
      {
        return;
      }
      payload = productStore.variantItem as IProduct;
      // console.log(payload)
      isExist = cart_products.value.some((i) => i.name === payload.name);
    }
    if(payload.status === 'out-of-stock'){
      toast.error(`Out of stock ${payload.web_item_name}`);
    }
    else if (!isExist) {
      const newItem = {
        ...payload,
        orderQuantity: orderQuantity.value ? orderQuantity.value : 1,
      };
      cart_products.value.push(newItem);
      toast.success(`${payload.web_item_name} added to cart`);
      addToCart(`${payload.web_item_name}`)
      addToCartMeta(`${payload.web_item_name}`)
    } else {
      cart_products.value.map((item) => {
        if (item.name === payload.name) {
          if (typeof item.orderQuantity !== "undefined") {
            // if ((item.quantity >= item.orderQuantity + orderQuantity.value) || item.on_backorder == 1) {
              item.orderQuantity =
                orderQuantity.value !== 1
                  ? orderQuantity.value + item.orderQuantity
                  : item.orderQuantity + 1;
              toast.success(
                `${orderQuantity.value} ${item.web_item_name} added to cart`
              );
              addToCart(`${item.web_item_name}`)
              addToCartMeta(`${item.web_item_name}`)
            // } else {
            //   toast.error(`No more quantity available for this product!`);
            //   orderQuantity.value = 1;
            // }
          }
        }
        return { ...item };
      });
    }
    localStorage.setItem("cart_products", JSON.stringify(cart_products.value));
    
    if (modalRef) {
      modalRef.click();
    }
    if(buyNow)
    {
      router.push('/checkout')
    }
  };

  // quantity increment
  const increment = () => {
   return orderQuantity.value = orderQuantity.value + 1;
  }

  // quantity decrement
  const decrement = () => {
   return orderQuantity.value =
      orderQuantity.value > 1
        ? orderQuantity.value - 1
        : (orderQuantity.value = 1);
  }

  // quantityDecrement
  const quantityDecrement = (payload: IProduct) => {
    cart_products.value.map((item) => {
      if (item.name === payload.name) {
        if (typeof item.orderQuantity !== "undefined") {
          if (item.orderQuantity > 1) {
            item.orderQuantity = item.orderQuantity - 1;
            toast.info(`Decrement Quantity For ${item.web_item_name}`);
          }
        }
      }
      return { ...item };
    });
    localStorage.setItem("cart_products", JSON.stringify(cart_products.value));
  };

  // remover_cart_products
  const removeCartProduct = (payload: IProduct) => {
    cart_products.value = cart_products.value.filter(
      (p) => p.name !== payload.name
    );
    toast.error(`${payload.web_item_name} remove to cart`);
    removeFromCart(`${payload.web_item_name}`)
    localStorage.setItem("cart_products", JSON.stringify(cart_products.value));
  };

  // cart product initialize
  const initializeCartProducts = () => {
    const cartData = localStorage.getItem("cart_products");
    if (cartData) {
      cart_products.value = JSON.parse(cartData);
    }
  };

  // clear cart
  const clear_cart = (silent: Boolean =false) => {
    let confirmMsg;
    if(!silent)
    {
      confirmMsg = window.confirm(
        "Are you sure deleted your all cart items ?"
      );
    }
    else
    {
      confirmMsg = true;
    }
    if (confirmMsg || silent) {
      cart_products.value = [];
    }
    localStorage.setItem("cart_products", JSON.stringify(cart_products.value));
  };
  // initialOrderQuantity
  const initialOrderQuantity = () => {
   return orderQuantity.value = 1;
  };

  // totalPriceQuantity
  const totalPriceQuantity = computed(() => {
    return cart_products.value.reduce(
      (cartTotal, cartItem) => {
        const { price_list_rate, orderQuantity } = cartItem;
        if (typeof orderQuantity !== "undefined") {
          const itemTotal = price_list_rate * orderQuantity;
          cartTotal.quantity += orderQuantity;
          cartTotal.total += itemTotal;
        }
        return cartTotal;
      },
      {
        total: 0,
        quantity: 0,
      }
    );
  });

  //handle cartOffcanvas
  const handleCartOffcanvas = () => {
    cartOffcanvas.value = !cartOffcanvas.value
  }

  // set local storage product when project are mounted
  onMounted(() => {
    initializeCartProducts();
  });


  // when router change than order quantity will be 1
  watch(() => route.path, () => {
    orderQuantity.value = 1
  })
  return {
    addCartProduct,
    cart_products,
    quantityDecrement,
    removeCartProduct,
    clear_cart,
    initialOrderQuantity,
    totalPriceQuantity,
    handleCartOffcanvas,
    cartOffcanvas,
    orderQuantity,
    increment,
    decrement,
  };
});
